import {Helmet} from "react-helmet";
import AdvisorTopBar from "@js/component/AdvisorTopBar";
import * as classNames from "@css/component/template/portal/MyProducts.module.scss";
import Heading from "@js/component/Heading";
import {Link} from "gatsby";
import ChevronRightIcon from "@js/component/icon/ChevronRightIcon";
import Box from "@js/component/Box";
import AdvisorMenu from "@js/component/AdvisorMenu";
import WorkMapView from "@js/component/WorkMapView";
import Main from "@js/component/Main";
import React from "react";
import {RouteComponentProps} from "@reach/router";

/**
 * @type WorkMapViewProps
 */
type WorkMapProps = RouteComponentProps & {
    id?: number;
};

const WorkMap = (props: WorkMapProps) => {
    const {
        id
    } = props;

    return (
        <Main className={ classNames.main }>
            <Helmet>
                <title>Mijn werkgebieden</title>
            </Helmet>
            <AdvisorTopBar />
            <div className={ classNames.container }>
                <section className={ classNames.myProductsPage }>
                    <header className={ classNames.header }>
                        <Heading className={ classNames.heading } element="h1" variant="large">
                            Dashboard voor energielabel-adviseurs
                        </Heading>
                        <div className={ classNames.breadcrumbs }>
                            <Link className={ classNames.previous } to="/portaal/dashboard">Dashboard</Link>
                            <ChevronRightIcon className={ classNames.icon } />
                            <Link className={ classNames.previous } to="/portaal/mijn-producten">Mijn producten</Link>
                            <ChevronRightIcon className={ classNames.icon } />
                            <span className={ classNames.current }>Mijn werkgebied</span>
                        </div>
                    </header>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.blockOne }>
                            <Box className={ classNames.box }>
                                <AdvisorMenu />
                            </Box>
                        </div>
                        <div className={ classNames.blockTwo }>
                            <Box>
                                <WorkMapView workMapId={ id }/>
                            </Box>
                        </div>
                    </div>
                </section>
            </div>
        </Main>
    );
}

export default WorkMap;