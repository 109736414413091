import React, {createRef, KeyboardEvent, MouseEvent, useEffect, useState} from 'react';
import {RouteComponentProps} from "@reach/router";
import {useSelector} from "react-redux";
import {graphql, navigate, useStaticQuery} from "gatsby";
import StoreState from "@js/store/StoreState";

import Heading from "@js/component/Heading";
import Input from "@js/component/Input";
import Button from "@js/component/Button";

import clsx from "clsx";

import * as classNames from "@css/component/WorkMapView.module.scss";
import WorkAreaVisibility from "@js/component/WorkAreaVisibility";
import Badge from "@js/component/Badge";
import PencilLightIcon from "@js/component/icon/PencilLightIcon";
import InputRadio from "@js/component/InputRadio";

/**
 * @type PriceTier
 */
type PriceTier = {
    area: string;
    buildingType: string;
    buildingTypeCategory: string;
    hash: string;
    id: number;
    methodology: string;
    price: string;
    requiresQuote: boolean;
};

/**
 * @type FilterData
 */
type FilterData = {
    workMapFilter: {
        buildingTypes: {
            label: string;
            value: string;
            category: string;
            areas: {
                label: string;
                value: string;
                quote: boolean
            }[];
        }[];
        methodologies: {
            label: string;
            value: string;
        }[];
    };
};

/**
 * @type WorkMapViewProps
 */
export type WorkMapViewProps = RouteComponentProps & {
    workMapId?: number;
};

const WorkMapView = (props: WorkMapViewProps) => {
    const {
        workMapId
    } = props;

    const data: FilterData = useStaticQuery(graphql`
        query {
            workMapFilter {
                buildingTypes {
                    label
                    value
                    category
                    areas {
                        label
                        value
                        quote
                    }
                }
                methodologies {
                    label
                    value
                }
            }
        }
    `);

    const formRef = createRef<HTMLFormElement>();
    const workAreaEntryFromRef = createRef<HTMLInputElement>();
    const workAreaEntryToRef = createRef<HTMLInputElement>();

    const [workMapName, setWorkMapName] = useState("");
    const [workMapVisible, setWorkMapVisible] = useState(true);
    const [showDeleteNotification, setShowDeleteNotification] = useState(false);
    const [safeToDeleteWorkMap, setSafeToDeleteWorkMap] = useState(false);
    const [workAreas, setWorkAreas] = useState([]);
    const [workAreaEntryFrom, setWorkAreaEntryFrom] = useState("");
    const [workAreaEntryTo, setWorkAreaEntryTo] = useState("");
    const [deliveryTime, setDeliveryTime] = useState("");
    const [dailyRequests, setDailyRequests] = useState("");
    const [workMapPartnership, setWorkMapPartnership] = useState(null);
    const [monthlyRequests, setMonthlyRequests] = useState("");
    const [productChoice, setProductChoice] = useState("");

    const [isFetching, setIsFetching] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string[]; }>({});

    const token = useSelector((storeState: StoreState) => storeState.security.token);

    const onAddWorkAreaRow = () => {
        if (
            workAreaEntryFromRef.current?.reportValidity() &&
            workAreaEntryToRef.current?.reportValidity()
        ) {
            if (!(workAreaEntryFrom == "") && !(workAreaEntryTo == "")) {
                const copyOfWorkAreas = [ ...workAreas ];

                copyOfWorkAreas.push({ from: workAreaEntryFrom, to: workAreaEntryTo });

                setWorkAreas(copyOfWorkAreas);

                setWorkAreaEntryFrom("");
                setWorkAreaEntryTo("");
            }
        }
    }

    const onRemoveWorkAreaRow = (index: number) => () => {
        const copyOfWorkAreas = [ ...workAreas ];

        copyOfWorkAreas.splice(index, 1);

        setWorkAreas(copyOfWorkAreas);
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key == "Enter") {
            event.preventDefault();

            onAddWorkAreaRow();
        }
    }

    const setWorkAreaVisibility = async () => {
        const visibility = workMapVisible ? false : true;

        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/workmap/${workMapId}/state?token=${token}`, {
            body: JSON.stringify({
                isVisible: visibility
            }),
            method: "PUT"
        });

        const data = await response.json();

        if(data.status == 'succeeded') {
            getMyData();
        }

        setWorkMapVisible(visibility);
    }

    const onSaveButtonClick = async (event: MouseEvent) => {
        event.preventDefault();

        setIsSaving(true);

        if (!formRef.current?.reportValidity()) {
            setIsSaving(false);
            return;
        }

        const addWorkMapId = workMapId ? `/${workMapId}` : '';

        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/workmap${ addWorkMapId }?token=${token}`, {
            body: JSON.stringify({
                name: workMapName,
                productChoice: productChoice,
                dailyRequests: dailyRequests,
                deliveryTime: deliveryTime,
                priceTiers: priceTiers.map((priceTier) => {
                    return {
                        price: parseFloat(priceTier.price),
                        methodology: priceTier.methodology,
                        buildingType: priceTier.buildingType,
                        area: priceTier.area,
                        requiresQuote: priceTier.requiresQuote
                    };
                }),
                workAreas: workAreas.map((workArea) => {
                    return {
                        //id: workArea.id,
                        startZipcode: workArea.from,
                        endZipcode: workArea.to
                    };
                })
            }),
            method: workMapId ? "PUT" : "POST"
        });

        const data = await response.json();

        if (data.status == "succeeded") {
            setIsSaving(false);

            navigate('/portaal/mijn-producten');
        } else {
            setErrors(data.errors);
            setIsSaving(false);
        }
    };

    const onDeleteButtonClick = (event: MouseEvent) => {
        event.preventDefault();

        setShowDeleteNotification(true);
        setSafeToDeleteWorkMap(true);
    }

    const onPerformDeleteWorkmap = async (event: MouseEvent) => {
        event.preventDefault();

        if(safeToDeleteWorkMap) {
            const response = await fetch(`${process.env.GATSBY_API_ADVISOR_URL}/workmap/${workMapId}?token=${token}`, {
                method: "DELETE"
            });

            const data = await response.json();

            if (data.status == "succeeded") {
                navigate('/portaal/mijn-producten');
            }

            return;
        }

        setSafeToDeleteWorkMap(false);
    }

    const defaultPriceTiers: PriceTier[] = [];

    data.workMapFilter.buildingTypes.map((buildingTypeOption) => {
        buildingTypeOption.areas.map((areaOption) => {
            data.workMapFilter.methodologies.map((methodologyOption) => {
                defaultPriceTiers.push({
                    area: areaOption.value,
                    buildingType: buildingTypeOption.value,
                    buildingTypeCategory: buildingTypeOption.category,
                    hash: "",
                    id: 0,
                    methodology: methodologyOption.value,
                    price: "",
                    requiresQuote: false
                });
            });
        });
    });

    const [priceTiers, setPriceTiers] = useState(defaultPriceTiers);
    const [showEditOption, setShowEditOption] = useState(false);

    const getMyData = async () => {
        setIsFetching(true);

        const response = await fetch(`${process.env.GATSBY_API_ADVISOR_URL}/workmap/${workMapId}?token=${token}`, {
            method: "GET"
        });

        const data = await response.json();

        setIsFetching(false);

        const workAreas = data.workAreas.map((workArea) => {
            return {
                id: workArea.id,
                from: workArea.startZipcode,
                to: workArea.endZipcode
            }
        });

        const newPriceTiers = [ ...priceTiers ];

        data.priceTiers.map((priceTier: PriceTier, index: number) => {
            if (priceTier.price == null) {
                priceTier.price = "";
            }
            else if (!(priceTier.price == "")) {
                priceTier.price = parseFloat(priceTier.price).toFixed(2);
            }

            newPriceTiers[index] = priceTier;
        });

        setPriceTiers(newPriceTiers);

        setWorkAreas(workAreas);
        setWorkMapName(data.name);
        setWorkMapPartnership(data.partnership);
        setWorkMapVisible(data.isVisible);
        setDeliveryTime(data.deliveryTime);
        setDailyRequests(data.dailyRequests);
        setMonthlyRequests(data.monthlyRequests);
        setProductChoice(data.productChoice);
    };

    useEffect(() => {
        if(workMapId) {
            getMyData();
        } else {
            setIsFetching(false);
        }
    }, []);

    const onShowEditOption = () => {
        if(showEditOption) {
            setShowEditOption(false);
        } else {
            setShowEditOption(true);
        }
    }

    return (
        <div className={ classNames.workMapView }>
            <form ref={ formRef }>
                <Heading className={ classNames.heading } element="h1" variant="medium">
                    { workMapName && workMapName }
                    { !isFetching && !workMapName && 'Nieuw werkgebied' }
                    { workMapId && !workMapPartnership && !isFetching &&
                        <PencilLightIcon
                            onClick={ () => onShowEditOption() }
                            className={ classNames.icon }
                        />
                    }
                    { workMapId && !workMapPartnership && !isFetching &&
                        <WorkAreaVisibility
                            isVisible={ workMapVisible }
                            onVisibilityClick={ () => setWorkAreaVisibility() }
                            variant="full"
                        />
                    }
                </Heading>
                {(!workMapId || showEditOption) &&
                    <div>
                        <fieldset className={ clsx(classNames.fieldset, classNames.workArea ) }>
                            <Heading className={ clsx(classNames.headingSub, classNames.blue) } element="h2" variant="small">Naam werkgebied *</Heading>
                            <Input className={ classNames.input }
                                   onChange={ (event) =>
                                       setWorkMapName(event.currentTarget.value)
                                    }
                                   id="workarea_name"
                                   name="workarea_name"
                                   value={ workMapName }
                                   placeholder="Geef je werkgebied een naam (verplicht)"
                                   required
                            />
                        </fieldset>
                    </div>
                }
                <div>
                    <fieldset className={classNames.fieldset}>
                        <Heading className={classNames.headingSub} element="h2" variant="small">Type *</Heading>
                        <div className={classNames.group}>
                            <InputRadio
                                checked={!productChoice || productChoice == "energyLabel"}
                                labelClassName={classNames.inputRadio}
                                name={"productChoice"}
                                onChange={(event) =>
                                    setProductChoice(event.currentTarget.value)
                                }
                                id={"type_energylabel"}
                                value="energyLabel"
                                autoComplete="honorific-prefix"
                            >
                                <label className={classNames.label} htmlFor="type_energylabel">
                                    Energielabel
                                </label>
                            </InputRadio>
                            <InputRadio
                                checked={productChoice == "customAdvice"}
                                labelClassName={classNames.inputRadio}
                                name={"productChoice"}
                                onChange={(event) =>
                                    setProductChoice(event.currentTarget.value)
                                }
                                id={"type_customadvice"}
                                value="customAdvice"
                                autoComplete="honorific-prefix"
                            >
                                <label className={classNames.label} htmlFor="type_customadvice">
                                    Maatwerk-advies
                                </label>
                            </InputRadio>
                        </div>
                    </fieldset>
                    <fieldset className={classNames.fieldset}>
                        <Heading className={classNames.headingSub} element="h2"
                                 variant="small">Postcodes {!workMapPartnership && '*'}</Heading>
                        {!workMapPartnership && !isFetching &&
                            <div className={classNames.workAreasInputs}>
                                <Input
                                    disabled={isFetching}
                                    inputRef={workAreaEntryFromRef}
                                    name="zip_code_from"
                                    onChange={(event) => setWorkAreaEntryFrom(event.currentTarget.value)}
                                    onKeyDown={onKeyDown}
                                    pattern="[1-9][0-9]{3}"
                                    placeholder="Bijv. 1120"
                                    //required
                                    value={workAreaEntryFrom}
                                />
                                <span className={classNames.divider}>t/m</span>
                                <Input
                                    disabled={isFetching}
                                    inputRef={workAreaEntryToRef}
                                    name="zip_code_to"
                                    onChange={(event) => setWorkAreaEntryTo(event.currentTarget.value)}
                                    onKeyDown={onKeyDown}
                                    pattern="[1-9][0-9]{3}"
                                    placeholder="Bijv. 1130"
                                    //required
                                    value={workAreaEntryTo}
                                />
                                <div className={classNames.buttonWrapper}>
                                    <Button className={classNames.addButton} onClick={onAddWorkAreaRow} type="button">
                                        Toevoegen
                                    </Button>
                                </div>
                            </div>
                        }

                        {workAreas.length == 0 &&
                            <div className={classNames.notification}>
                                Er zijn nog geen postcode gebieden toegevoegd (verplicht)
                            </div>
                        }

                        {workAreas.length > 0 &&
                            <div className={classNames.workAreas}>
                                <ul className={classNames.list}>
                                    {workAreas.map((workArea, index) => (
                                        <li className={classNames.listItem} key={index}>
                                            <span>{workArea.from} t/m {workArea.to}</span>
                                            {!workMapPartnership && !isFetching &&
                                                <button
                                                    className={classNames.remove}
                                                    onClick={onRemoveWorkAreaRow(index)}
                                                    type="button"
                                                >
                                                    Verwijder
                                                </button>
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }
                    </fieldset>

                    <div className={classNames.errors}>
                        {errors.workAreas &&
                            errors.workAreas.map((error, index) => (
                                <div key={index} className={classNames.notification}>{error}</div>
                            ))}
                    </div>
                </div>

                <Heading className={classNames.headingSub} element="h2" variant="small">Levering</Heading>
                <div className={classNames.div}>
                    {!workMapPartnership && !isFetching &&
                        <>
                                <div className={classNames.labelWrapper}>
                                    <label className={classNames.label} htmlFor="delivery_time">
                                        Hoe snel na aanvraag kan je het energielabel leveren? *
                                    </label>
                                </div>

                                <div className={classNames.deliveryTime}>
                                    <Input className={clsx(classNames.input, {
                                        [classNames.error]: errors.deliveryTime
                                    })}
                                           disabled={isFetching}
                                           id="delivery_time"
                                           name="delivery_time"
                                           onChange={(event) => setDeliveryTime(event.currentTarget.value)}
                                           value={deliveryTime}
                                           min="1"
                                           required
                                    /> werkdagen
                                </div>
                        </>
                    }

                    <label className={ classNames.label } htmlFor="daily_requests">
                        Hoeveel aanvragen per { workMapPartnership ? 'maand' : 'dag' } kun je verwerken? { !workMapPartnership && '*' }
                    </label>
                    <Input
                        className={ clsx(classNames.input, {
                            [classNames.error]: errors.dailyRequests
                        }) }
                        disabled={ isFetching || workMapPartnership !== null }
                        id="daily_requests"
                        name="daily_requests"
                        onChange={ (event) => setDailyRequests(event.currentTarget.value) }
                        required
                        value={ workMapPartnership ? monthlyRequests : dailyRequests }
                        min="1"
                    />

                    { (!dailyRequests || !deliveryTime) && !workMapPartnership && !isFetching &&
                        <div className={ classNames.notification }>
                            Bovenstaande velden zijn verplicht
                        </div>
                    }
                </div>

                { !workMapPartnership && !isFetching &&
                <>
                    <Heading className={ classNames.headingSub } element="h2" variant="small">Tarieven</Heading>
                    <div className={ classNames.tip }>
                        <Badge className={ classNames.badge }>tip</Badge>
                        <p className={ classNames.paragraph }>
                            Wanneer je geen bedrag invult, wordt je product niet aangeboden
                        </p>
                    </div>
                    <div>
                        { data.workMapFilter.buildingTypes.map((buildingTypeOption) => (
                            <div className={ classNames.table } key={ buildingTypeOption.value }>
                                <div className={ classNames.head }>
                                <div className={ classNames.row }>
                                    <div className={ classNames.heading }>
                                        { buildingTypeOption.category == 'private'
                                            ? 'Woningoppervlak '
                                            : 'Labelplichtig oppervlak '
                                        }
                                        { buildingTypeOption.label }
                                    </div>
                                    { data.workMapFilter.methodologies.map((methodologyOption) => (
                                        <div className={ classNames.heading } key={ methodologyOption.value }>
                                            { methodologyOption.label }
                                        </div>
                                    )) }
                                </div>
                                </div>
                                <div className={ classNames.body }>
                                { buildingTypeOption.areas.map(areaOption => {
                                    const returnRequiresQuote = priceTiers.find((priceTier) => {
                                        return priceTier.buildingType == buildingTypeOption.value &&
                                            (priceTier.area === '250+' || priceTier.area === 'business_2500+')
                                    })?.requiresQuote;

                                    return (
                                        <div className={classNames.row} key={areaOption.value}>
                                            <div className={classNames.data}>{areaOption.label} m²</div>
                                            {data.workMapFilter.methodologies.map((methodologyOption) => {
                                                const priceValue = priceTiers.find((priceTier) => {
                                                                    return priceTier.area == areaOption.value &&
                                                                        priceTier.methodology == methodologyOption.value &&
                                                                        priceTier.buildingType == buildingTypeOption.value
                                                                })?.price;

                                                const inputIsDisabled = (
                                                    isFetching ||
                                                    areaOption.value === '250+' && returnRequiresQuote ||
                                                    areaOption.value === 'business_2500+' && returnRequiresQuote
                                                );

                                                const isDuplicateEntry = errors.priceTiers && errors.priceTiers.some((error) => {
                                                   return error.buildingType == buildingTypeOption.value &&
                                                        error.methodology == methodologyOption.value &&
                                                        error.areaCode == areaOption.value
                                                });

                                                return (
                                                    <div className={classNames.data} key={methodologyOption.value}>
                                                        <div className={classNames.inputWrapper}>
                                                            <span className={classNames.euro}>&euro;</span>
                                                            <Input
                                                                className={ clsx(classNames.input, { [classNames.duplicate]: isDuplicateEntry }) }
                                                                disabled={ inputIsDisabled }
                                                                onBlur={() => {
                                                                    const index = priceTiers.findIndex((priceTier) => {
                                                                        return priceTier.area == areaOption.value &&
                                                                            priceTier.methodology == methodologyOption.value &&
                                                                            priceTier.buildingType == buildingTypeOption.value
                                                                    });

                                                                    const priceTiersCopy = [...priceTiers];

                                                                    if (priceTiersCopy[index] && !(priceTiersCopy[index].price == "")) {
                                                                        priceTiersCopy[index].price = parseFloat(
                                                                            priceTiers[index].price
                                                                        ).toFixed(2);
                                                                    }

                                                                    setPriceTiers(priceTiersCopy);
                                                                }}
                                                                onChange={(event) => {
                                                                    const index = priceTiers.findIndex((priceTier) => {

                                                                        return priceTier.area == areaOption.value &&
                                                                            priceTier.methodology == methodologyOption.value &&
                                                                            priceTier.buildingType == buildingTypeOption.value
                                                                    });

                                                                    const priceTiersCopy = [...priceTiers];

                                                                    if (priceTiersCopy[index]) {
                                                                        priceTiersCopy[index].price = event.currentTarget.value;
                                                                    }

                                                                    setPriceTiers(priceTiersCopy);
                                                                }}
                                                                min="1"
                                                                step="0.10"
                                                                value={ priceValue ?? '' }
                                                                type="number"
                                                            />
                                                            <span className={classNames.suffix}>
                                                                { buildingTypeOption.category === 'business' ? 'excl. btw' : 'incl. btw' }
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            { (areaOption.value === '250+' || areaOption.value === 'business_2500+') &&
                                                <div className={ classNames.requireQuote }>
                                                    <input
                                                        className={ classNames.checkbox }
                                                        type="checkbox"
                                                        name="require_quote"
                                                        disabled={ isFetching }
                                                        value={ returnRequiresQuote }
                                                        checked={ returnRequiresQuote }
                                                        onChange={(event) => {
                                                            const index = priceTiers.findIndex((priceTier) => {
                                                                return (priceTier.area == '250+' || priceTier.area == 'business_2500+') &&
                                                                    priceTier.buildingType == buildingTypeOption.value &&
                                                                    priceTier.methodology == 'basic'
                                                            });

                                                            const priceTiersCopy = [...priceTiers];

                                                            if (priceTiersCopy[index]) {
                                                                priceTiersCopy[index].requiresQuote = event.currentTarget.checked;
                                                            }

                                                            setPriceTiers(priceTiersCopy);
                                                        }}
                                                    />
                                                    Hiervoor moet een offerte opgevraagd worden
                                                </div>
                                            }
                                        </div>
                                    );
                                })}
                                </div>
                            </div>
                        )) }
                        { showDeleteNotification &&
                            <div className={ classNames.notification }>
                                <Heading element="h2" variant="small">Weet je zeker dat je dit werkgebied wilt verwijderen?</Heading>
                                <div>
                                    <Button
                                        className={ classNames.button }
                                        onClick={ onPerformDeleteWorkmap }
                                    >Ja, ik weet het zeker</Button>
                                    <Button
                                        className={ classNames.button }
                                        onClick={ () => setShowDeleteNotification(false) }>Nee, annuleren</Button>
                                </div>
                            </div>
                        }
                        { errors.priceTiers &&
                            <div className={ classNames.notification }>
                                <Heading element="h2" variant="small">Je hebt twee keer een producttarief ingevuld in een overlappend werkgebied</Heading>
                            </div>
                        }

                        <div className={ clsx(classNames.errors, classNames.deliveryTimeError) }>
                            { errors.deliveryTime &&
                            errors.deliveryTime.map((error, index) => (
                                <div key={ index } className={ classNames.notification }>{ error }</div>
                            )) }
                        </div>

                        <div className={ clsx(classNames.errors, classNames.dailyRequests) }>
                            { errors.dailyRequests &&
                            errors.dailyRequests.map((error, index) => (
                                <div key={ index } className={ classNames.notification }>{ error }</div>
                            )) }
                        </div>
                        <div className={ classNames.buttonWrapper }>
                            {workMapId && !showDeleteNotification &&
                                <span
                                    className={ classNames.removeButton }
                                    onClick={ onDeleteButtonClick }
                                >Verwijder werkgebied</span>
                            }
                            <Button
                                className={ classNames.button }
                                disabled={ isSaving || isFetching || !workMapName || workAreas.length == 0 || !dailyRequests || !deliveryTime }
                                onClick={ onSaveButtonClick }
                            >
                                { isSaving && "Bezig met opslaan..." }
                                { !(isSaving) && "Opslaan" }
                            </Button>
                        </div>
                    </div>
                </>
                }
            </form>
        </div>
    );
}

export default WorkMapView;